/* eslint-disable */
import {
    SET_STORAGE_VARIABLE,
} from '../mutation_types'

const state = {
  user_info: {},
  public_key: '',
  private_key: '',
  lang: "zh"
}

const mutations = {
  //设置用户个人信息
  [SET_STORAGE_VARIABLE] (state, data) {
    try {
      Object.keys(data).forEach(function(key){
          state[key] = data[key]
      })
    } catch (err) {
      console.log(err)
    }
  },
}


const actions = {
  [SET_STORAGE_VARIABLE] ({ commit, state }, data) {
    //保存信息
    if (data !== undefined) {
      let localStorage = window.localStorage
      let old_data = JSON.parse(localStorage.getItem('STORAGE_VARIABLE'))
      if (!old_data) {
        localStorage.setItem('STORAGE_VARIABLE', JSON.stringify(data))
        commit(SET_STORAGE_VARIABLE, data)
      } else {
        Object.keys(data).forEach(function(key){
            old_data[key] = data[key]
        })
        localStorage.setItem('STORAGE_VARIABLE', JSON.stringify(old_data))
        commit(SET_STORAGE_VARIABLE, old_data)
      }
    } else {
      // 加载localstorage数据
      if (localStorage.getItem('STORAGE_VARIABLE')) {
        data = JSON.parse(localStorage.getItem('STORAGE_VARIABLE'))
        commit(SET_STORAGE_VARIABLE, data)
      } else {
        console.info('global variable no data')
      }
    }
  }
}

export default {
  state,
  mutations,
  actions,
}

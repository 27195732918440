import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './vuex/store'
import systemSettings from './utils/systemSettings'
import { Header, Container, Footer, Main, Button, Carousel, CarouselItem, Card, Input, Icon, Image, Form, FormItem, Pagination, Menu, MenuItem, Message, MessageBox} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss';
import vueConfig from './vue-config'
import Http from './utils/http'

Vue.config.productionTip = false

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.use(vueConfig)
Vue.use(systemSettings)
Vue.use(Http)
Vue.use(Header)
Vue.use(Container)
Vue.use(Footer)
Vue.use(Main)
Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Card);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Pagination);
Vue.use(Menu);
Vue.use(MenuItem);

Vue.setValueByKey('api_path', 'https://api.cat-planet.com/mobile_api/v2')

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
import global_variable from './modules/global_variable'
import storage_variable from './modules/storage_variable'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global_variable,
    storage_variable
  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})

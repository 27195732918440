<template>
  <div id="app">
    <el-container>
      <el-header style="box-shadow: 1px 1px 3px 3px #c0c4cc;">
        <div class="nav">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="navSelect">
            <el-menu-item index="1">
              <div class="icon_container">
                <i class="el-icon-house"></i>首页
              </div>
            </el-menu-item>
            <el-menu-item index="2">
              <div class="icon_container">
                <i class="el-icon-notebook-2"></i>笔记
              </div>
            </el-menu-item>
            <el-button class="top_button" type="text" v-if="!isSignedIn()" @click="signIn">登录</el-button>
            <el-button class="top_button" type="text" v-else @click="signOut">退出</el-button>
          </el-menu>
        </div>
      </el-header>
      <div class="main_container">
        <el-main>
          <router-view/>
        </el-main>
      </div>
      <el-footer>
        ©2022-2023 Alice Sheng Personal Website | 盛楠的个人网站
      </el-footer>
    </el-container>
  </div>
</template>
<style>
.nav {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .main_container {
    width: 1200px;
    margin: 0 auto;
  }
}
</style>
<script>
import Vue from 'vue'
export default {
  name: 'App',
  data() {
    return {
      activeIndex: '1'
    }
  },
  created() {
    this.initProject()
    if (this.$route.path.indexOf('notes') > 0) {
      this.activeIndex = '2'
    }
  },
  methods: {
    signIn() {
      this.$router.push('/login')
    },
    signOut() {
      Vue.setStorageVariable({'private_key': ''});
      Vue.setStorageVariable({'public_key': ''});
      this.$router.push('/')
    },
    navSelect(index) {
      switch(index) {
        case '1': {
          this.$router.push('/')
          break;
        }
        case '2': {
          this.$router.push('/notes')
          break;
        }
        default: {
          break;
        }
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}
.top_button {
  height: 60px;
  float: right;
}
</style>

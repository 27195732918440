<template>
  <div class="login_index">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-document"></i>
        <span>笔记登录</span>
      </div>
      <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="100px" >
        <el-form-item label="登录用户" prop="username">
          <el-input v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="password">
          <el-input v-model="loginForm.password" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<style lang="scss">
@import "../../element-variables.scss";
.login_index .box-card .clearfix {
  color: $--color-primary;
  text-align: left;
  font-weight: bold;
}
</style>
<script>
import Vue from 'vue'
export default {
  name: 'loginIndex',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入登录用户', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    if (this.isSignedIn()) {
      this.$router.push('/')
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postUrl('/user_info/auth', {
            username: this.loginForm.username,
            password: this.loginForm.password
          }).then((response) => {
            if (response.data.code === 20000) {
              Vue.setStorageVariable({'private_key': response.data.private_key});
              Vue.setStorageVariable({'public_key': response.data.public_key});
              this.$router.push('/')
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

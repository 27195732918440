import { mapState } from 'vuex'
import store from './vuex/store'
export default function (Vue) {
  Vue.setValueByKey = function(key, value) {
    window.localStorage.setItem(key, value);
  }
  Vue.getValueByKey = function(key) {
    return window.localStorage.getItem(key);
  }
  Vue.setGlobalVariable = function(hash) {
    store.dispatch('SET_GLOBAL_VARIABLE', hash)
  }
  // {key: value}
  Vue.setStorageVariable = function(hash) {
    store.dispatch('SET_STORAGE_VARIABLE', hash)
  }
  Vue.mixin({
    created: function () {
      //页面配置常量
      const configs = {
      }
      this.$configs = configs
    },
    computed: {
      ...mapState({
        currentLang: state => state.storage_variable.lang,
        currentPrivateKey: state => state.storage_variable.private_key,
        currentPublicKey: state => state.storage_variable.public_key
      })
    },
  })
}

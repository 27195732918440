import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/homeIndex.vue'
import Notes from '@/views/notes/noteIndex.vue'
import NoteNew from '@/views/notes/noteNew.vue'
import NoteEdit from '@/views/notes/noteEdit.vue'
import NoteShow from '@/views/notes/noteShow.vue'
import Login from '@/views/login/loginIndex.vue'
import Nothing from '@/views/nothingPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/notes',
    component: Notes
  },
  {
    path: '/notes/new',
    component: NoteNew
  },
  {
    path: '/notes/:noteId/edit',
    component: NoteEdit
  },
  {
    path: '/notes/:noteId/show',
    component: NoteShow
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '*',
    component: Nothing
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router


import store from '../vuex/store'
export default function (Vue) {
  Vue.mixin({
    methods: {
      initProject() {
        store.dispatch('SET_GLOBAL_VARIABLE')
        store.dispatch('SET_STORAGE_VARIABLE')
      },
      isSignedIn() {
        if (this.currentPublicKey && this.currentPrivateKey && this.currentPublicKey !== '' && this.currentPrivateKey !== '') {
          return true
        } else {
          return false
        }
      }
    }
  })
}

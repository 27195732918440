<template>
  <div class="home">
    <el-carousel indicator-position="outside" :height="lineHeight" arrow="always" :interval="5000">
      <el-carousel-item v-for="item in imageArray" :key="item" :height="lineHeight">
        <div class="scroll_image" :style="{ height: lineHeight + 'px', lineHeight: lineHeight + 'px', backgroundImage: 'url(' + item + ')' }">
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-tickets"></i>
        <span>个人简介</span>
      </div>
      <div class="resume">
        <div class="avatar">
          <el-image src="https://files.sweetysoft.com/image_after_2023_02_13/4485/39611676204488_.pic.jpg">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
        <div class="content">
          <div class="title">盛楠（ShengNan）</div><br>
          <div class="detail">
            我2016年毕业于辽宁工业大学外国语学院，我的专业是英语。<br>
            我为人乐观、开朗、热心、上进、善于倾听。<br>
            我的爱好是唱歌和撸猫。<br>
            我拥有专业英语四级证书，以及雅思6.0的成绩，可以和英语母语者流畅沟通。<br>
            我从事过行政人事、外贸采购、软件测试，了解Python以及Html，了解前后端运行原理。<br>
            我的邮箱：2542389575@qq.com
          </div>
          <div class="detail">
          I graduated from the Foreign Language institute of Liaoning University of Technology in 2016, and my major is English.<br>
          I am optimistic, cheerful, enthusiastic, motivated and good at listening.<br>
          My hobbies are singing and cats.<br>
          I have a Certificate TEM-4 and IELTS 6.0 Band, so I can communicate fluently with native English speakers.<br>
          I have been engaged in administrative personnel, foreign trade procurement, software testing, understand Python and Html, and the operating principles of front-end and back-end.<br>
          Email: 2542389575@qq.com
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 20px;">
      <div slot="header" class="clearfix">
        <i class="el-icon-cherry"></i>
        <span>家中萌宠</span>
      </div>
      <el-carousel :interval="4000" type="card" height="300">
        <el-carousel-item v-for="item in petImageArray" :key="item">
          <div class="scroll_image" :style="{ height: '300px', backgroundImage: 'url(' + item + ')' }">
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-card>
  </div>
</template>
<style lang="scss">
@import "../../element-variables.scss";
.home .box-card .clearfix {
  color: $--color-primary;
  text-align: left;
  font-weight: bold;
}
.home .resume{
  display: flex;
  .avatar {
    width: 300px;
  }
  .content {
    text-align: left;
    padding: 20px;
    flex: 1;
    color: $--color-primary;
    .title {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
    }
    .detail {
      font-size: 16px;
      line-height: 24px;
    }
    .detail:not(:first-child) {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 700px) {
  .home .resume {
    flex-direction: column;
    .avatar {
      width: 100%;
    }
  }
}

</style>
<script>
export default {
  name: "homeIndex",
  data() {
    return {
      lineHeight: '680',
      imageArray: [
        "https://files.sweetysoft.com/image_after_2023_02_13/4491/1761676225480.jpg",
        "https://files.sweetysoft.com/image_after_2023_02_13/4490/1741676224953_.pic.jpg",
        "https://files.sweetysoft.com/image_after_2023_02_13/4495/1771676225480.jpg",
        "https://files.sweetysoft.com/image_after_2023_02_13/4493/1781676225481.jpg",
        'https://files.sweetysoft.com/image_after_2023_02_13/4487/39691676204493_.jpg',
        "https://files.sweetysoft.com/image_after_2023_02_13/4494/1791676225481.jpg",
        "https://files.sweetysoft.com/image_after_2023_02_13/4488/39621676204489_.jpg"
      ],
      petImageArray: [
        'https://files.sweetysoft.com/image_after_2023_02_13/4501/39741676204498.jpg',
        'https://files.sweetysoft.com/image_after_2023_02_13/4500/39761676204499.jpg',
        'https://files.sweetysoft.com/image_after_2023_02_13/4499/39771676204502.jpg',
        'https://files.sweetysoft.com/image_after_2023_02_13/4497/39781676204502.jpg',
        'https://files.sweetysoft.com/image_after_2023_02_13/4496/39821676204511.jpg'
      ]
    }
  },
  created() {
     window.addEventListener("resize", this.resizeEventHandler)
  },
  mounted() {
    this.resizeEventHandler()
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  methods: {
    resizeEventHandler() {
      let height = (document.documentElement.clientWidth - 40) * 500 / 863
      height = height > 680 ? 680 : height
      this.lineHeight = height.toString()
      document.getElementsByClassName('el-carousel__container')[0].style.height = height + 'px'
    }
  }
}
</script>
<style>
  .home .scroll_image {
    background-size: cover;
    background-position: center;
  }
  .home .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }

  .home .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .home .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import md5 from 'js-md5';
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true; // 携带cookie

Vue.use(VueAxios, axios)

export default function (Vue) {
    Vue.mixin({
        methods: {
            getUrl(path, params) {
                let url = Vue.getValueByKey('api_path') + path
                if(path != '/user_info/auth'){
                  //不是登录接口，signature加密
                  params.signature = this.getSign(params)
                }
                return new Promise((resolve, reject)=>{
                    this.$http.get(url, { params: params }).then((response) => {
                        resolve(response)
                    }, (error) => {
                        //console.error(error)
                        reject(error)
                    });
                })

            },
            postUrl(path, params, noTip_success=false, noTip_error=false) {
                let url = Vue.getValueByKey('api_path') + path
                if(path != '/user_info/sign_in'){
                  //不是登录接口，signature加密
                  params.signature = this.getSign(params)
                }
                return new Promise((resolve, reject)=>{
                    this.$http.post(url, params).then((response) => {
                        if (response.data.code ===  20000) {
                          if (!noTip_success) {
                            this.$message({
                              message: response.data.message,
                              type: 'success'
                            })
                          }
                        } else {
                          if (!noTip_error) {
                            this.$message({
                              message: response.data.message,
                              type: 'error'
                            })
                          }
                        }
                        resolve(response)
                    }, (error) => {
                        // console.error(error)
                        reject(error)
                    });
                })
            },

            getSign(obj) {
              for (var propName in obj) {
                  if (obj[propName] === null || obj[propName] === undefined) {
                      delete obj[propName];
                  }
              }
              const ordered = {};
              Object.keys(obj).sort().forEach(function (key) {
                  ordered[key] = obj[key];
              });
              let keyValueArray = []
              Object.keys(ordered).forEach(function (key) {
                 let str = key + "=" + ordered[key]
                 keyValueArray.push(str)
              })
              keyValueArray.push("private_key=" + this.currentPrivateKey)
              return md5(keyValueArray.join('&'))
            }
        }
    })
}

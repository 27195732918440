<template>
  <div class="note_index">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-document"></i>
        <span>我的笔记</span>
        <el-button @click="newNote" v-if="isSignedIn()" style="float: right; padding: 3px 0" type="text">新建笔记</el-button>
      </div>
      <div :key="item.note_id" v-for="item in notes" class="note_details">
        <div class="notes_cell">
          <div style="flex: 1;">
            <el-button  @click="showNote(item)" type="text">{{item.created_at}}&nbsp;&nbsp;{{item.title}}</el-button>
          </div>
          <div style="justify-content: flex-end;">
            <el-button v-if="isSignedIn()" @click="editNote(item)" style="" type="primary">编辑</el-button>
            <el-button v-if="isSignedIn()" @click="deleteNote(item)" style="" type="danger">删除</el-button>
          </div>
        </div>
      </div>
      <!--
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
      -->
    </el-card>
  </div>
</template>
<style lang="scss">
@import "../../element-variables.scss";
.note_index .box-card .clearfix {
  color: $--color-primary;
  text-align: left;
  font-weight: bold;
}
.note_index {
  .note_details {
    text-align: left;
    font-size: 18px;
    padding: 5px 0
  }
  .notes_cell {
    display: flex;
  }
}
</style>
<script>
export default {
  name: 'noteIndex',
  data() {
    return {
      notes: []
    }
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    loadPage() {
      this.getUrl('/activity/all_notes', {
      }).then((response) => {
        if (response.data.code === 20000) {
          this.notes = response.data.notes
        }
      })
    },
    showNote(note) {
      this.$router.push('/notes/' + note.note_id +'/show')
    },
    newNote() {
      this.$router.push('/notes/new')
    },
    editNote(note) {
      this.$router.push('/notes/' + note.note_id +'/edit')
    },
    deleteNote(note) {
        this.$confirm('此操作将永久删除该笔记, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postUrl('/activity/delete_note', {
            note_id: note.note_id,
            public_key: this.currentPublicKey,
          }).then((response) => {
            if (response.data.code === 20000) {
              this.loadPage()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除操作'
          });
        });

    }
  }
}
</script>

<template>
  <div class="note_new">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-document-add"></i>
        <span>新建笔记</span>
      </div>
      <el-form :model="noteForm" :rules="rules" ref="noteForm" label-width="100px" >
        <el-form-item label="笔记标题" prop="title">
          <el-input v-model="noteForm.title"></el-input>
        </el-form-item>
        <el-form-item label="笔记正文" prop="value">
          <wang-editor v-model="noteForm.value"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('noteForm')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<style lang="scss">
@import "../../element-variables.scss";
.note_new .box-card .clearfix {
  color: $--color-primary;
  text-align: left;
  font-weight: bold;
}
</style>
<script>
import WangEditor from '@/components/WangEditor.vue'
export default {
  name: 'noteNew',
  components: {
    WangEditor
  },
  data() {
    return {
      noteForm: {
        title: '',
        value: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        value: [
          { required: true, message: '请输入正文', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postUrl('/activity/create_note', {
            public_key: this.currentPublicKey,
            title: this.noteForm.title,
            content: this.noteForm.value
          }).then((response) => {
            if (response.data.code === 20000) {
              this.$router.push('/notes')
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

/* eslint-disable */
import {
  SET_GLOBAL_VARIABLE,
} from '../mutation_types'

const state = {
  user_info: {},
  cacw_balance: 0,
  cac_balance: 0,
  cac_activity_rule_link: "",
  purchase_history_back_height_pos: 0,
  my_performance_back_height_pos: 0
}

const mutations = {
  //设置用户个人信息
  [SET_GLOBAL_VARIABLE] (state, data) {
    try {
      Object.keys(data).forEach(function(key){
          state[key] = data[key]
      })
    } catch (err) {
      console.log(err)
    }
  },
}


const actions = {
  [SET_GLOBAL_VARIABLE] ({ commit, state }, data) {
    //保存信息
    if (data !== undefined) {
      commit(SET_GLOBAL_VARIABLE, data)
    }
  }
}

export default {
  state,
  mutations,
  actions,
}

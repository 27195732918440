<template>
  <div class="note_show">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-document-copy"></i>
        <span>{{noteForm.title}}</span>
      </div>
      <div style="text-align: left;" v-html="noteForm.value" />
    </el-card>
  </div>
</template>
<style lang="scss">
@import "../../element-variables.scss";
.note_show .box-card .clearfix {
  color: $--color-primary;
  text-align: left;
  font-weight: bold;
}
</style>
<script>
export default {
  name: 'noteShow',
  mounted() {
    this.getUrl('/activity/show_note', {
      public_key: this.currentPublicKey,
      note_id: this.$route.params.noteId
    }).then((response) => {
      if (response.data.code === 20000) {
        this.noteForm.title = response.data.note.title
        this.noteForm.value = response.data.note.content
        this.noteForm.is_private = response.data.note.private
      }
    })
  },
  data() {
    return {
      noteForm: {
        title: '',
        value: '',
        is_private: true
      }
    }
  },
}
</script>
